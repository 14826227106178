import tw, { styled } from 'twin.macro';
import React from 'react';

// import AllServices from '../utils/getServices';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Paragraph from '../components/atoms/Paragraph';
import Heading from '../components/atoms/Heading';

const Container = styled.div`
  background-image: url('/images/app-store-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
`;

const AppStore = () => {
  // const getServices = new AllServices();
  // const [apps, setApps] = React.useState();

  // const getApk = async () => {
  //   const employee = await getServices.getApk('EMPLOYEE');
  //   const client = await getServices.getApk('CUSTOMER');
  //   setApps([
  //     { type: 'employee', link: employee.data.app_download_link },
  //     { type: 'client', link: client.data.app_download_link },
  //   ]);
  // };

  // useEffect(() => {
  //   getApk();
  // }, []);

  const appsList = [
    {
      id: '1',
      name: 'ProField',
      description:
        'A mobile app for field resources – to perform & manage daily activities like Sales/service/AMC/Breakdown activities with smart workflows, Attendance management, claim/expense management.',
      image: '/images/upanal/app-1.png',
      downloadLink:
        'https://play.google.com/store/apps/details?id=com.upanal.employeeapp',
    },
    {
      id: '2',
      name: 'StartScan',
      description:
        'A mobile app for end users- register and raise service/AMC/Breakdown requests, get real time progress notification on the request, view history of all the requests, view & buy/upgrade products with exchange benefits.',
      image: '/images/upanal/create-ser-req.png',
      downloadLink:
        'https://play.google.com/store/apps/details?id=com.startso.startscan',
    },
  ];

  return (
    <Layout>
      <Seo title="StartSo App Store" />
      <div tw="w-full">
        <div tw="text-center mt-20 mb-10">
          <Heading isLarge>
            Start<span tw="text-secondary">SO</span> AppStore
          </Heading>
        </div>
        <Container>
          {appsList.map((item, index) => (
            <div key={item.id} tw="container mx-auto px-4">
              <div
                css={[
                  tw`md:flex items-center justify-center md:gap-24 gap-8 py-16`,
                  (index + 1) % 2 === 0 && tw`flex-row-reverse`,
                ]}
              >
                <div tw="w-full md:w-6/12 py-6">
                  <Paragraph hasTextWhite>
                    <span tw="font-semibold uppercase">
                      #{index < 9 ? `0${index + 1}` : index + 1} - {item.name}
                    </span>
                  </Paragraph>
                  <Heading hasTextWhite type="h1">
                    GET THIS APP
                  </Heading>
                  <div tw="mt-5">
                    <Paragraph hasTextWhite>{item.description}</Paragraph>
                    <div tw="pt-6 -ml-3">
                      <a
                        target="_blank"
                        href={item.downloadLink}
                        rel="noreferrer"
                      >
                        <img
                          src="/images/google-play.png"
                          width="200"
                          alt={item.name}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div tw="md:w-6/12 w-full">
                  <img
                    tw="w-max lg:max-w-md mx-auto"
                    src={item.image}
                    alt="app"
                    height="400"
                  />
                </div>
              </div>
              {appsList.length !== index + 1 && (
                <div tw="max-w-screen-xl mx-auto h-0.5 bg-white" />
              )}
            </div>
          ))}
        </Container>
      </div>
    </Layout>
  );
};
export default AppStore;
